<template>
	<div class="navBox">
		<!--h5 端布局 -->
		<nav class="navbar">
			<img class="logo" src="../../assets/nav/m3.png" alt="" />
			<div class="right">
				<img src="@/assets/nav/i18.png" alt="" @click="showIn18" @mouseleave="hideIn18" />
				<img src="../../assets/h5Home/menu.png" @click="show('1')" alt="" />
			</div>
			<div class="navbar-nav" id="navs">
				<div class="top">
					<img class="logo" src="../../assets/nav/m3.png" alt="" />
					<img src="../../assets/h5Home/hide.png" class="hide" @click="show('2')" alt="" />
				</div>
				<ul>
					<template v-for="item in tabList" :class="{ active: item.active }">
						<li
							class="nav-item"
							style="display: flex; justify-content: space-between; align-items: center"
							:key="item.key"
							v-if="item.key == 2"
							@click="childrenFlag = !childrenFlag"
						>
							<div>
								{{ item.name }}
							</div>
							<img v-show="!childrenFlag" src="../../assets/h5Home/xia.png" alt="" />
							<img v-show="childrenFlag" src="../../assets/h5Home/shang.png" alt="" />
						</li>
						<li class="nav-item" v-else :key="item.key" @click="toUrl(item)">
							<div>
								{{ item.name }}
							</div>
						</li>
						<div class="box" v-if="item.key == 2 && childrenFlag" :key="item.key">
							<div class="item">
								<div class="item-top">MarsVerse</div>
								<div class="item-body">
									<img src="@/assets/nav/mv1.png" alt="" />
									<div>{{ $t('Product.mv1') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mv2.png" alt="" />
									<div>{{ $t('Product.mv2') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mv3.png" alt="" />
									<div>{{ $t('Product.mv3') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mv4.png" alt="" />
									<div>{{ $t('Product.mv4') }}</div>
								</div>
							</div>
							<div class="item">
								<div class="item-top">MarsChain</div>
								<div class="item-body">
									<img src="@/assets/nav/mc1.png" alt="" />
									<div>{{ $t('Product.mc1') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mc2.png" alt="" />
									<div>{{ $t('Product.mc2') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mc3.png" alt="" />
									<div>{{ $t('Product.mc3') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mc4.png" alt="" />
									<div>{{ $t('Product.mc4') }}</div>
								</div>
							</div>
							<div class="item item3">
								<div class="item-top">MarsProtocol</div>
								<div class="item-body">
									<img src="@/assets/nav/mp1.png" alt="" />
									<div>{{ $t('Product.mp1') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mp2.png" alt="" />
									<div>{{ $t('Product.mp2') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mp3.png" alt="" />
									<div>{{ $t('Product.mp3') }}</div>
								</div>
								<div class="item-body">
									<img src="@/assets/nav/mp4.png" alt="" />
									<div>{{ $t('Product.mp4') }}</div>
								</div>
							</div>
						</div>
					</template>
				</ul>
			</div>
			<div class="in18box" v-show="in18Flag">
				<div
					class="item"
					v-for="(item, index) in in18List"
					:key="index"
					:class="{ item_active: item.active }"
					@touchstart="setIn18(item)"
				>
					{{ item.name }}
				</div>
			</div>
		</nav>
		<!-- pc 端布局 -->
		<div class="nav">
			<div class="body">
				<div class="left">
					<img src="@/assets/nav/m3.png" alt="" />
				</div>
				<div class="center"
					v-if="navFlag == true">
					<div
						class="item"
						v-for="(item, index) in tabList"
						:key="index"
						:class="{ active: item.active }"
						@click="toUrl(item)"
						@mouseover="showButton(item)"
					>
						<a>
							{{ item.name }}
						</a>
					</div>
					<!-- <div>{{ t('message.hello') }}</div>
				<div>{{ $t('message.hello') }}</div> -->
				</div>
				<div class="right">
					<img src="@/assets/nav/i18.png" alt="" @mouseover="showIn18" />
				</div>
			</div>
			<div class="box" v-if="boxFlag" @mouseleave="hideButton">
				<div class="item">
					<div class="item-top">MarsVerse</div>
					<div class="item-body">
						<img src="@/assets/nav/mv1.png" alt="" />
						<div>{{ $t('Product.mv1') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mv2.png" alt="" />
						<div>{{ $t('Product.mv2') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mv3.png" alt="" />
						<div>{{ $t('Product.mv3') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mv4.png" alt="" />
						<div>{{ $t('Product.mv4') }}</div>
					</div>
				</div>
				<div class="item">
					<div class="item-top">MarsChain</div>
					<div class="item-body">
						<img src="@/assets/nav/mc1.png" alt="" />
						<div>{{ $t('Product.mc1') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mc2.png" alt="" />
						<div>{{ $t('Product.mc2') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mc3.png" alt="" />
						<div>{{ $t('Product.mc3') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mc4.png" alt="" />
						<div>{{ $t('Product.mc4') }}</div>
					</div>
				</div>
				<div class="item item3">
					<div class="item-top">MarsProtocol</div>
					<div class="item-body">
						<img src="@/assets/nav/mp1.png" alt="" />
						<div>{{ $t('Product.mp1') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mp2.png" alt="" />
						<div>{{ $t('Product.mp2') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mp3.png" alt="" />
						<div>{{ $t('Product.mp3') }}</div>
					</div>
					<div class="item-body">
						<img src="@/assets/nav/mp4.png" alt="" />
						<div>{{ $t('Product.mp4') }}</div>
					</div>
				</div>
			</div>
			<div class="in18box" v-show="in18Flag" @mouseleave="hideIn18">
				<div
					class="item"
					v-for="(item, index) in in18List"
					:key="index"
					:class="{ item_active: item.active }"
					@click="setIn18(item)"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();
let boxFlag = ref(false);
let navFlag = ref(true);
let in18Flag = ref(false);
let childrenFlag = ref(false);

let tabList = reactive([
	{
		name: t('tabList.list1'),
		key: 1,
		active: false,
		url: '/',
	},
	{
		name: t('tabList.list2'),
		key: 2,
		active: false,
	},
	{
		name: t('tabList.list3'),
		key: 3,
		active: false,
		url: '/Portfolio',
	},
	{
		name: t('tabList.list4'),
		key: 4,
		active: false,
		url: '/News',
	},
	{
		name: t('tabList.list5'),
		key: 5,
		active: false,
		url: '/support',
	},
	// {
	// 	name: t('tabList.list6'), // team
	// 	key: 6,
	// 	active: false,
	// 	url: '/team',
	// },
	{
		name: t('tabList.list7'), // Grant
		key: 7,
		active: false,
		url: '/grant',
	},
	{
		name: t('tabList.list8'), // Grant
		key: 8,
		active: false,
		url: '/Blog',
	},
]);
const in18List = reactive([
	{
		name: '简体中文',
		id: 'zh',
		key: 1,
		active: true,
	},
	{
		name: 'English',
		id: 'en',
		key: 2,
		active: false,
	},
	{
		name: '日本語',
		id: 'jap',
		key: 3,
		active: false,
	},
	{
		name: '한글',
		id: 'kor',
		key: 4,
		active: false,
	},
	{
		name: 'Deutsch',
		id: 'ger',
		key: 5,
		active: false,
	},
	{
		name: 'Tiếng Việt',
		id: 'vie',
		key: 6,
		active: false,
	},
]);
const show = (type) => {
	let navs = document.getElementById('navs');
	if (type == 1) {
		navs.style.display = 'flex';
	} else {
		navs.style.display = 'none';
	}
};

const showButton = (item) => {
	if (item.key == 2) {
		boxFlag.value = true;
	} else {
		boxFlag.value = false;
	}
};

const showIn18 = () => {
	in18Flag.value = true;
};

const hideButton = () => {
	boxFlag.value = false;
};

const hideIn18 = () => {
	in18Flag.value = false;
};

const toUrl = (item) => {
	tabList.map((items) => {
		if (item.name == items.name) {
			if (item.key != 2) {
				show('2');
				router.push(item.url);
				items.active = true;
			}
		} else if (item.key != 2) {
			items.active = false;
		}
	});
};

const setIn18 = (item) => {
	in18List.map((items) => {
		items.active = false;
		if (item.name == items.name) {
			items.active = true;
			locale.value = item.id;
			localStorage.setItem('currentLanage', item.id);
			window.location.reload();
		}
	});
	hideIn18();
};
watch(
	() => route.path,
	(item, oldPath) => {
		tabList.map((items) => {
			items.active = false;
			if (item == '/' + items.name) {
				items.active = true;
			} else if (item == '/' || item == '首页') {
				tabList[0].active = true;
			} else if (item == '/Portfolio') {
				tabList[2].active = true;
			} else if (item == '/News') {
				tabList[3].active = true;
			} else if (item == '/support') {
				tabList[4].active = true;
			} 
			// else if (item == '/team') {
			// 	tabList[5].active = true;
			// } 
			else if (item == '/grant') {
				tabList[6].active = true;
			} else if (item == '/Blog' || item == '/avaNews') {
				tabList[7].active = true;
			}
		});
		if(item == '/coreteam') {
			navFlag  = false
		}
	},
	{ immediate: true },
);

onMounted(() => {
	// 检测元素是否在视图窗口的函数
	const act = () => {
		in18List.map((items) => {
			if (locale.value == items.id) {
				items.active = true;
			} else {
				items.active = false;
			}
		});
	};
	act();
});
computed(() => {
	return locale.value;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* max-width 最大宽度  当前屏幕小于992px会应用当前媒体查询里面的样式 */
@media screen and (max-width: 768px) {
	.navBox {
		width: 100%;
		height: 50px;
	}
	.nav {
		display: none;
	}
	.navbar {
		box-sizing: border-box;
		width: 100vw;
		height: 50px;
		position: fixed;
		top: 0;
		z-index: 2;
		background-color: rgba(5, 5, 5, 1);
		color: #fff;
		padding: 0 11px 0;
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: space-between;
		// position: relative;
		.logo {
			width: 45px;
		}
		.right {
			display: flex;
			align-items: center;
			img {
				width: 36px;
				margin-right: 4px;
			}
			img:nth-child(2) {
				width: 30px;
				margin-right: 0;
			}
		}
	}
	.navbar-nav {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		flex-direction: column;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background-color: rgba(5, 5, 5, 1);
		.top {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 11px 0;
			.logo {
				width: 45px;
			}
			.hide {
				width: 26px;
			}
		}
		ul {
			width: 100%;
			list-style-type: none;
			.nav-item {
				width: 100%;
				padding: 0 20px;
				height: 20px;
				font-weight: 500;
				font-size: 14px;
				margin-top: 40px;
				img {
					width: 20px;
				}
			}
		}
	}
	.box {
		padding: 24px 4px 0;
		width: 100%;
		height: 170px;
		text-align: left;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		.item {
			display: flex;
			width: 120px;
			flex-direction: column;
			align-items: flex-start;
			.item-top {
				text-align: center;
				margin-bottom: 15px;
				box-sizing: border-box;
				padding: 8.7px 17.5px;
				line-height: 11px;
				font-size: 11px;
				font-weight: 500;
				background: #8b8883;
				cursor: pointer;
				border: 1px solid rgba(139, 136, 131, 1);
				border-radius: 30px;
				color: rgba(255, 255, 255, 1);
				background: rgba(0, 0, 0, 0);
				cursor: pointer;
			}
			.item-top:hover {
				color: rgba(0, 0, 0, 1);
				background: #ffffff66;
				border: 1px solid transparent;
				background-clip: padding-box, border-box;
				background-origin: padding-box, border-box;
				background-image: linear-gradient(to right, #ffffff66, #ffffff66),
					linear-gradient(90deg, #ffffff, #99939300, #ffffff);
			}
			.item-body {
				width: 100px;
				height: 21px;
				margin-top: 5px;
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: 10px;
				padding: 6px 0 6px 4px;
				overflow: hidden; //超出的文本隐藏
				text-overflow: ellipsis; //溢出用省略号显示
				white-space: nowrap; //溢出不换行
				img {
					width: 15px;
					margin-right: 8px;
				}
			}
			.item-body:hover {
				border-radius: 15px;
				background: rgba(66, 66, 66, 1);
			}
		}
		.item3 {
			// width: 340px;
		}
	}
	.in18box {
		position: absolute;
		width: 120px;
		height: 288px;
		top: 50px;
		right: 0;
		background: rgba(217, 217, 217, 0.6);
		padding: 10px 0 10px 8px;
		font-size: 14px;
		border-radius: 0 0 10px 10px;
		.item {
			margin-bottom: 10px;
			width: 100px;
			height: 36px;
			line-height: 36px;
			padding-left: 10px;
			border-radius: 10px;
			color: #000000;
		}
		.item_active {
			background: rgba(231, 231, 231, 1);
		}
	}
}

/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.navBox {
		width: 100%;
		height: 80px;

		.navbar {
			display: none;
		}
	}
	.nav {
		width: 100%;
		height: 80px;
		color: #ffffff;
		position: fixed;
		top: 0;
		z-index: 999;
		// position: relative;
		.body {
			box-sizing: border-box;
			background: #151515;
			padding: 10px 40px;
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left {
				width: 78px;
				img {
					width: 100%;
				}
			}
			.center {
				display: flex;
				.item {
					margin-right: 50px;
					border-radius: 6px;
					a {
						padding: 10px;
					}
				}
				.item:hover {
					background: #402601;
				}
				a:hover {
					// color: #000000 !important;
				}
				.active {
					a {
						color: #000000 !important;
					}
					background: rgba(253, 170, 51, 1);
				}
			}
			.right {
				width: 36px;
				height: 36px;
				img {
					width: 100%;
				}
			}
		}
		.box {
			position: absolute;
			width: 1050px;
			height: 378px;
			top: 80px;
			left: 435px;
			background: #262626;
			padding: 48px 58px 0 58px;
			text-align: left;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			background: url('../../assets/nav/back.png');
			background-size: 1050px 378px;
			background-repeat: no-repeat;
			border-radius: 10px;
			.item {
				display: flex;
				width: 244px;
				flex-direction: column;
				align-items: flex-start;
				.item-top {
					text-align: center;
					margin-bottom: 30px;
					box-sizing: border-box;
					padding: 16px 32px;
					line-height: 22px;
					font-size: 22px;
					font-weight: 500;
					background: #8b8883;
					cursor: pointer;
					border: 3px solid rgba(139, 136, 131, 1);
					border-radius: 60px;
					color: rgba(255, 255, 255, 1);
					background: rgba(0, 0, 0, 0);
					cursor: pointer;
				}
				.item-top:hover {
					color: rgba(0, 0, 0, 1);
					background: #ffffff66;
					border: 3px solid transparent;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(to right, #ffffff66, #ffffff66),
						linear-gradient(90deg, #ffffff, #99939300, #ffffff);
				}
				.item-body {
					width: auto;
					height: 42px;
					margin-top: 10px;
					display: flex;
					align-items: center;
					cursor: pointer;
					font-size: 18px;
					padding: 6px 9px;
					white-space: nowrap; //溢出不换行
					img {
						width: 30px;
						margin-right: 9px;
					}
				}
				.item-body:hover {
					border-radius: 30px;
					background: rgba(66, 66, 66, 1);
				}
			}
			.item3 {
				width: 340px;
			}
		}
		.in18box {
			position: absolute;
			width: 140px;
			height: 300px;
			top: 80px;
			right: 0;
			background: rgba(217, 217, 217, 0.6);
			padding: 10px 0 10px 8px;
			// text-align: left;
			border-radius: 0 0 10px 10px;
			.item {
				margin-bottom: 10px;
				width: 120px;
				height: 36px;
				line-height: 36px;
				padding-left: 10px;
				border-radius: 10px;
				color: #000000;
			}
			.item_active {
				background: rgba(231, 231, 231, 1);
			}
		}
	}
}
</style>
